import React from 'react';
import styled from 'styled-components';
import { RecommendationsSection } from 'views/home/recommendations-section';
import { Offer } from 'views/offer';
import { Content } from 'views/offer/components/Content';
import { Header } from 'views/offer/components/Header';
import { Image } from 'views/offer/components/Image';
import { ImageWrapper } from 'views/offer/components/ImageWrapper';
import { List } from 'views/offer/components/List';
import { ListItem } from 'views/offer/components/ListItem';
import { Paragraph } from 'views/offer/components/Paragraph';
import { Section } from 'views/offer/components/Section';
import { Subtitle } from 'views/offer/components/Subtitle';
import { getOfferBreadcrumbsLinks } from 'views/offer/helpers';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import { graphql, useStaticQuery } from 'gatsby';
import OtherTreatmentsJson from 'jsons/otherTreatment.json';
import { Layout } from 'layouts';

const SImageWrapper = styled(ImageWrapper)`
  max-width: 2500px;
`;

const Offer4 = ({ location }) => {
  const { pathname } = location;
  const breadcrumbsLinks = getOfferBreadcrumbsLinks(pathname);

  const images = useStaticQuery(graphql`
    query {
      one: file(relativePath: { eq: "ig-wolu-1.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      two: file(relativePath: { eq: "ig-wolu-2.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      three: file(
        relativePath: {
          eq: "img-24_large&ideale medycyna estetyczna warszawa wola wolumetria twarzy.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      four: file(
        relativePath: {
          eq: "img-28_large&ideale medycyna estetyczna warszawa wola wolumetria policzkow kwas hialuronowy.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
      fourth: file(relativePath: { eq: "loyalty.png" }) {
        childImageSharp {
          gatsbyImageData(width: 750, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <Layout>
      <Offer otherTreatments={OtherTreatmentsJson.liftingWolumetryczny}>
        <Header breadcrumbsLinks={breadcrumbsLinks}>
          Wolumetria twarzy kwasem hialuronowym
        </Header>
        <Content>
          <Section>
            <SImageWrapper>
              <Image
                src={images.fourth.childImageSharp}
                alt="wolumetria twarzy kwasem hialuronowym ideale medycyna estetyczna warszawa wola"
              />
            </SImageWrapper>
            <Subtitle>
              Wskazania do zabiegu wolumetrii poprawiącej owal twarzy
            </Subtitle>
            <Paragraph>
              <b>Wolumetrię twarzy</b>, tj. wolumetria policzków, wolumetria
              brody, stosuje się do uzupełnienia utraty objętości twarzy
              związanej z wiekiem lub szczególnym układem anatomicznym ubytków
              tkanki podskórnej twarzy. Wraz z upływem lat tkanka podskórna
              ulega stopniowemu zanikowi i w pewnym momencie wpływa to
              niekorzystnie na wygląd konturów twarzy. Dodatkowo na skutek
              utraty elastyczności skóry, policzki obniżają się powodując
              tworzenie się fałdów i bruzd oraz obniżenie kącików ust, co
              negatywnie wpływa na owal twarzy.
            </Paragraph>
            <Paragraph noMarginBottom>
              Lifting wolumetryczny twarzy warto stosować się w celu:
            </Paragraph>
            <List>
              <ListItem>Podkreślenia kości policzkowych</ListItem>
              <ListItem>Modelowania policzków</ListItem>
              <ListItem>Modelowania brody</ListItem>
              <ListItem>Modelowania żuchwy</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>Opis zabiegu wolumetrii twarzy</Subtitle>
            <Paragraph>
              Zabieg liftingu wolumetrycznego twarzy zawsze poprzedzony jest
              indywidualną konsultacją lekarską. Wolumetria twarzy polega na
              podaniu za pomocą igły lub tępo zakończonej kaniuli w odpowiednie
              warstwy skóry kwasu hialuronowego, który wypełni, uwydatni i
              wymodeluje twarz nadając jej smuklejszy wygląd.
            </Paragraph>
            <Paragraph>
              Zabieg wolumetrii twarzy lub poszczególnych okolic twarzy tj.
              wolumetria policzków lub wolumetria brody wykonuje się w miejscu
              wcześniej znieczulonym przy pomocy specjalnego kremu lub
              preparatem zawierającym znieczulenie, dzięki czemu jest on
              praktycznie bezbolesny.
            </Paragraph>
          </Section>
          <Section>
            <Subtitle>
              Po jakim czasie i jak długo widoczne są efekty zabiegu wolumetrii
              twarzy?
            </Subtitle>
            <Paragraph>
              Efekty zabiegu widoczne są natychmiast, natomiast pełny efekt
              pojawia się po kilku dniach po ustąpieniu ewentualnego obrzęku i
              utrzymuje się od 12 do 24 miesięcy. Wchłaniający się kwas zapewnia
              doskonałe nawilżenie tkanek dając efekt zdrowej i wypoczętej
              skóry. Zdjęcia ukazują efekt zabiegu u konkretnego pacjenta.
              Efekty zabiegu mogą się różnić w zależności od indywidualnych cech
              pacjenta, liczby powtórzeń zabiegu, stosowania się pacjenta do
              zaleceń pozabiegowych oraz umiejętności i doświadczenia osoby
              przeprowadzającej zabieg.
            </Paragraph>
            <Subtitle>Zdjęcia przed po zabiegu wolumetrii twarzy</Subtitle>
            <ImageWrapper>
              <Image
                src={images.one.childImageSharp}
                alt="wolumetria twarzy przed po ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.two.childImageSharp}
                alt="lifiting wolumetrczyny ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.three.childImageSharp}
                alt="wolumetria twarzy przed po ideale medycyna estetyczna warszawa wola"
              />
              <Image
                src={images.four.childImageSharp}
                alt="wolumetria twarzy przed po ideale medycyna estetyczna warszawa wola"
              />
            </ImageWrapper>
          </Section>
          <Section>
            <Subtitle>Cena zabiegu wolumetrii twarzy</Subtitle>
            <Paragraph>
              Cena zabiegu wolumetrii twarzy kwasem hialuronowym zaczyna się od
              1800 zł. Przy pierwszej wizycie odbierz swoją kartę lojalnościową
              i otrzymaj -30% na czwarty zabieg medycyny estetycznej wykonany w
              Klinice IDEALE.
            </Paragraph>
          </Section>
          <RecommendationsSection />
          <Section>
            <Subtitle>Przeciwwskazania do zabiegu wolumetrii twarzy</Subtitle>
            <List>
              <ListItem>Okres ciąży, połogu i karmienia piersią</ListItem>
              <ListItem>Skłonność do powstawania blizn przerostowych</ListItem>
              <ListItem>Nadwrażliwość na kwas hialuronowy</ListItem>
              <ListItem>Choroby nowotworowe</ListItem>
              <ListItem>Zaburzenia krzepnięcia</ListItem>
              <ListItem>Miejscowy stan zapalny skóry</ListItem>
            </List>
          </Section>
          <Section>
            <Subtitle>
              Czym jest wolumetria twarzy z wykorzystaniem kwasu hialuronowego?
            </Subtitle>
            <Paragraph>
              Wolumetria twarzy, inaczej lifting wolumetryczny z wykorzystaniem
              kwasu hialuronowego to procedura medycyny estetycznej, która ma na
              celu przywrócenie objętości, modelowanie owalu twarzy i jej
              konturów poprzez podanie kwasu hialuronowego w określone obszary.
              Kwas hialuronowy jest naturalnie występującym składnikiem skóry,
              który odpowiada za nawilżenie, elastyczność i objętość tkanek.
            </Paragraph>
            <Paragraph>
              Podczas zabiegu wolumetrii twarzy, lekarz medycyny estetycznej
              podaje kwas hialuronowy w strategiczne miejsca na twarzy w celu
              korekcji utraty objętości, asymetrii, opadania owalu twarzy.
              Obszary, w które najczęściej wprowadza się kwas hialuronowy w tym
              zabiegu, obejmują policzki, okolice żuchwy lub brodę, w zależności
              od indywidualnych potrzeb pacjenta.
            </Paragraph>
            <Paragraph>
              Wolumetria twarzy daje subtelne modelowanie policzków, brody lub
              całej twarzy, przywrócenie utraconych objętości i poprawę konturów
              twarzy. Kwas hialuronowy wiąże wodę, co{' '}
              <strong>nawilża i ujędrnia skórę</strong>, a także nadaje młodszy
              wygląd twarzy. Efekty zabiegu wolumetrii twarzy kwasem
              hialuronowym są natychmiastowe, najlepsze po około dwóch
              tygodniach i mogą się utrzymywać nawet do 2 lat, w zależności od
              rodzaju użytego kwasu hialuronowego oraz indywidualnych czynników
              pacjenta.
            </Paragraph>
            <Paragraph>
              Przed przystąpieniem do zabiegu <b>wolumetrii twarzy z kwasem </b>
              , odbywa się konsultacja z doświadczonym lekarzem medycyny
              estetycznej, który ocenia indywidualne potrzeby pacjenta oraz
              przeprowadza szczegółowy wywiad dotyczący stanu zdrowia i
              ewentualnych przeprowadzonych wcześniej zabiegów z zakresu
              medycyny estetycznej i chirurgii plastycznej.
            </Paragraph>
            <Subtitle>
              Kiedy warto wykonać wolumetrię twarzy z użyciem kwasu
              hialuronowego?
            </Subtitle>
            <Paragraph>
              Lifting wolumetryczny twarzy kwasem hialuronowym jest zalecany dla
              osób po 35-40 roku życia, które zauważyły na swojej twarzy utratę
              jędrności skóry. Zabieg ten jest szczególnie efektywny w
              przypadkach, gdzie twarz utraciła swoją objętość, szczególnie w
              okolicach policzków, brody czy skroni. Wolumetria twarzy pomaga
              również w likwidacji zmarszczek, doliny łez, czy zapadniętych
              skroni. Podsumowując, wolumetria twarzy jest idealna dla każdego,
              kto chce poprawić owal twarzy, wyszczuplić ją i nadać jej młodszy
              wygląd.
            </Paragraph>
            <Subtitle>Czym jest kwas hialuronowy?</Subtitle>
            <Paragraph>
              Kwas hialuronowy to związek chemiczny z grupy glikozaminoglikanów,
              który występuje naturalnie w organizmach żywych, w tym w ludzkim
              ciele. Jest to substancja o żelowej konsystencji, która pełni
              ważną rolę w utrzymaniu odpowiedniego nawilżenia i elastyczności
              skóry, a także w procesach regeneracji tkanek.
            </Paragraph>
            <Paragraph>
              Kwas hialuronowy jest składnikiem istotnym dla zdrowej skóry,
              stawów i oczu. W skórze występuje głównie w warstwie skóry
              właściwej, gdzie jest odpowiedzialny za utrzymanie odpowiedniego
              poziomu nawilżenia. Posiada zdolność wiązania i zatrzymywania
              dużej ilości wody, co przyczynia się do utrzymania jędrności,
              elastyczności i gładkości skóry.
            </Paragraph>
            <Paragraph>
              Wraz z upływem czasu, produkcja naturalnego kwasu hialuronowego w
              organizmie zmniejsza się, co może prowadzić do utraty nawilżenia i
              powstawania zmarszczek. W związku z tym, kwas hialuronowy jest
              szeroko wykorzystywany w medycynie estetycznej. Może być stosowany
              w postaci zastrzyków lub jako składnik kosmetyków, takich jak
              kremy, serum czy maski, w celu nawilżenia skóry, redukcji
              zmarszczek i poprawy ogólnego wyglądu skóry.
            </Paragraph>
            <Paragraph>
              Ponadto, kwas hialuronowy może być stosowany w medycynie, na
              przykład w leczeniu chorób stawów, takich jak osteoartroza, gdzie
              wstrzykiwany jest bezpośrednio do stawu w celu zmniejszenia bólu i
              poprawy funkcji stawu.
            </Paragraph>
            <Subtitle>Dlaczego IDEALE?</Subtitle>
            <Paragraph>
              <b>
                IDEALE Medycyna Estetyczna to gabinet stworzony przez kobietę
                dla kobiet.
              </b>{' '}
              Dążymy do tego aby nasi Pacjenci poczuli się piękni i dostrzegli w
              sobie wyjątkowe, unikatowe cechy. Podkreślamy to co interesujące.
              Słuchamy i doradzamy. Nasz doświadczony lekarz medycyny
              estetycznej ułoży dla Ciebie plan, indywidualnie dopasuje zabiegi,
              doradzi w temacie pielęgnacji skóry, dzięki czemu wyjdziesz od nas
              piękna, naturalna i promienna, a Twoja skóra będzie wyglądać na
              młodszą i bardziej wypoczętą. Nasza klinika mieści się w Warszawie
              na Woli, tuż przy stacji metra Młynów zapewniając łatwy dojazd
              naszym pacjentom. Wokół kliniki znajduje się również wiele miejsc
              parkingowych.
            </Paragraph>
            <Paragraph>
              W IDEALE skupiamy się na dostarczaniu skutecznych i bezpiecznych
              zabiegów medycyny estetycznej, które czerpiemy prosto z
              międzynarodowych kongresów i szkoleń. Dążymy do zapewnienia
              najwyższej jakości usług, a nasz zespół stale poszerza swoją
              wiedzę i umiejętności, aby sprostać Twoim oczekiwaniom.
            </Paragraph>
            <Paragraph>
              Razem z nami odkryjesz potencjał swojej urody i swoją unikatowość.
              Poczujesz się zadbana i zrelaksowana. Zaufaj naszemu
              doświadczeniu, abyś mogła cieszyć się piękną, zadbaną i młodą
              skórą.
            </Paragraph>
            <Paragraph>
              IDEALE, Gabinet Medycyny Estetycznej, ul. Sokołowska 9, Wola,
              Warszawa.
            </Paragraph>
          </Section>
        </Content>
      </Offer>
    </Layout>
  );
};

export default Offer4;

export const Head = () => <SEO tags={TAGS.wolumetriaTwarzy} />;
